import React, { useState } from "react";
import { graphql } from "gatsby";
import { builder, Builder, BuilderComponent } from "@builder.io/react";
import { Helmet } from "react-helmet";
import { Provider } from "../context/Context";

import "../../static/css/upsell.css";

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = "PetLab Co.";
const defaultDescription = "Supplies, Treats, Snacks & Chews - Join The Pack";

const UpsellTemplate = props => {
  const { data } = props;
  const userToken = "token";

  const models = data?.allBuilderModels;
  const content = models.oneUpsell.content;

  const {
    countdownDuration = 900,
    title: pageTitle,
    description: pageDescription
  } = content?.data;

  const selectorData = data?.upsellCheckoutData;

  const endTime = new Date();
  endTime.setSeconds(endTime.getSeconds() + countdownDuration);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [upsellCart, setUpsellCart] = useState([]);
  const [salesTaxes] = useState({
    label: "Sales tax",
    tax: []
  });
  const [orderData] = useState({});

  if (!Builder.isEditing) return null;

  const context = {
    selectorData: selectorData,
    currentIndex: currentIndex,
    setCurrentIndex: setCurrentIndex,
    buttonClicked: buttonClicked,
    setButtonClicked: setButtonClicked,
    userToken: userToken,
    upsellCart: upsellCart,
    setUpsellCart: setUpsellCart,
    salesTaxes: salesTaxes,
    orderData: orderData,
    countDownEnd: endTime
  };

  return (
    <Provider value={context}>
      <Helmet>
        <title>{pageTitle || defaultTitle}</title>
        <meta
          name="description"
          content={pageDescription || defaultDescription}
        />
        <meta name="funnel-id" content={content.id} />
        <meta name="product-selector-id" content={content.data.selector.id} />
      </Helmet>
      <BuilderComponent name={Builder.previewingModel} content={content} />
    </Provider>
  );
};

export default UpsellTemplate;

export const upsellQuery = graphql`
  query {
    allBuilderModels {
      oneUpsell(options: { cachebust: true, includeRefs: true }) {
        content
      }
    }

    upsellCheckoutData {
      checkoutUrl
      currency {
        code
        symbol
      }
      offers {
        checkoutData {
          country
          discounted_price
          image
          price
          product_id
          quantity
          splitFlag
          split_price
          title
          title_index
          variant_id
        }
        discountCode
        discountRate
        displayTitle
        nextIndexNo
        nextIndexYes
        offerId
        productName
        variantId
        tag
      }
      store
      checkout
    }
  }
`;
